body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, Helvetica, sans-serif;
    background-color: #f1f1f1;
    position: relative;
    min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#loading{
  display: none;
}

#loading-icon-img {
  display: none;
}

.loading-icon-css {
  display: none;
}

.loading-image {
  display: none;
}

#loading{
  display: none;
}

#overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #4e4e4e;
  transition: opacity 500ms;
  opacity: 0.8;
  z-index: 100;
}

#div-loading{
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000000;
  color: #fff;
  text-align: center;
}