.wrapper-payment {
	display: flex;
	align-items: center;
	justify-content: center;
}

.slider {
	height: 200px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.slider .slide-track {
	-webkit-animation: scroll 30s linear infinite;
	animation: scroll 20s linear infinite;
	display: flex;
	width: calc(250px * 14);
}

.slider .slide {
    margin: 50px;
	height: 120px;
	width: 120px;
}

.slider .slide img {
    padding: 5px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #bbbbbb;
}

@-webkit-keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-150px * 7));
	}
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-150px * 7));
	}
}

@media only screen and (max-width: 600px) {
    .slider .slide {
        margin: 20px;
        height: 120px;
        width: 120px;
    }

    .slider .slide-track {
        -webkit-animation: scroll 90s linear infinite;
        animation: scroll 10s linear infinite;
        display: flex;
        width: calc(250px * 14);
    }
}

@media only screen and (max-width: 1100px) {
    .slider .slide {
        margin: 40px;
        height: 120px;
        width: 120px;
    }
}