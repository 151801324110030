.container-contact-us{
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 17px;
}

.container-contact-us:after{
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: url("../../../../public/media/core/contactUs1.webp") no-repeat center;
	background-size: cover;
	filter: blur(50px);
	z-index: -1;
}


.contact-box-contact-us{
	max-width: 850px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #fff;
	box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.19);
    border-radius: 10px;
    margin-top: 80px;
}

.left-contact-us{
	background: url("../../../../public/media/core/contactUs2.jpeg") no-repeat center;
	background-size: cover;
	height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.right-contact-us{
	padding: 25px 40px;
}

.title-contact-us{
	position: relative;
	padding: 0 0 10px;
	margin-bottom: 10px;
}

.title-contact-us:after{
	content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    border-radius: 2px;
    background-color: #39c6ea;
}

.field-contact-us{
	width: 100%;
	border: 2px solid rgba(0, 0, 0, 0);
	outline: none;
	background-color: rgba(230, 230, 230, 0.6);
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	margin-bottom: 22px;
	transition: .3s;
    border-radius: 50px;
}

.field-contact-us:hover{
	background-color: rgba(0, 0, 0, 0.1);
}

.btn-send-contact-us{
	width: 100%;
	padding: 0.5rem 1rem;
	background-color: #39c6ea;
	color: #fff;
	font-size: 1.1rem;
	border: none;
	outline: none;
	cursor: pointer;
	transition: .3s;
    border-radius: 50px;
}

.btn-send-contact-us:hover{
    background-color: #1aafea;
}

.field-contact-us:focus{
    border: 2px solid rgba(30,85,250,0.47);
    background-color: #fff;
}

@media screen and (max-width: 880px){
	.contact-box-contact-us{
		grid-template-columns: 1fr;
	}
	.left-contact-us{
		height: 200px;
	}

    .left-contact-us{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}