.card-top-search{
    margin-top: 20px;
    display: flex !important;
    box-shadow: 0px 8px 48px rgba(2, 67, 71, 0.08) !important;
}

.card-top-search:hover {
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.24) !important;
}

.card-top-search-title {
    color: rgb(13, 13, 13) !important;
    text-decoration: none !important;
}

.card-top-search-title:hover {
    color: rgb(1, 180, 174) !important;
    border-bottom: 1px solid rgb(11, 219, 238);
}

.card-result-found {
    width: 90%;
    max-width: 345px;
    box-shadow: 0px 8px 48px rgba(39, 40, 41, 0.08) !important;
}

.card-result-found:hover img {
    transform: scale(1.1);
}

.card-result-found:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}

.card-result-found:hover .card-result-found-title {
    color: rgb(1, 180, 174);
}

@media only screen and (max-width: 899px) {
    .top-search {
        display: none;
    }
}