.serive-gird-item-content{
    padding-top: 20px;
    text-align: justify;
}

@media screen and (max-width: 900px)
{
    .serive-gird-item-content{
        padding-top: 20px !important;
        text-align: justify;
    }

    .css-1ld3b9g-MuiGrid-root>.MuiGrid-item {
        padding-top: 20px !important;
    }
}