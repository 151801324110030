.header-menu {
    padding-top: 16px;
}

.header-menu .link-nav-bar {
    color: white;
    font-size: 32px;
    text-decoration: none;
}

.header-menu .header-menu-option {
    padding-top: 32px;
    padding-right: 32px;
}
  
#header {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: auto;
}

#header .header {
    min-height: 4vh;
    background-color: rgba(31, 30, 30, 0.24);
    transition: 0.3s ease background-color;
}

#header .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 10px;
}

#header .nav-list ul {
    list-style: none;
    position: absolute;
    background-color: rgb(31, 30, 30);
    width: 100vw;
    height: 100vh;
    left: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s ease left;
}

#header .nav-list ul.active {
    left: 0%;
}

.link-nav-bar-index {
    color: rgb(27, 236, 247);
}

#header .link-nav-bar:hover{
    color: rgb(27, 236, 247);
    text-decoration: none;
    border-bottom: 1px solid rgb(11, 219, 238);
}

.auth-nav-bar:hover{
    color: rgb(3, 160, 238);
    text-decoration: none;
    border-bottom: 1px solid rgb(11, 219, 238);
}

.auth-nav-bar {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.auth-nav-bar-button {
    color: #ffd274 !important;
    font-size: 24px !important;
    text-transform: none !important;
}

#header .hamburger {
    height: 60px;
    width: 60px;
    display: inline-block;
    border: 3px solid white;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    cursor: pointer;
    transform: scale(0.8);
    margin-right: 20px;
}

#header .hamburger:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 3px solid white;
    animation: hamburger_puls 1s ease infinite;
}

#header .hamburger .bar {
    height: 2px;
    width: 30px;
    position: relative;
    background-color: white;
    z-index: -1;
}

#header .hamburger .bar::after,
#header .hamburger .bar::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    background-color: white;
    transition: 0.3s ease;
    transition-property: top, bottom;
}

#header .hamburger .bar::after {
    top: 8px;
}

#header .hamburger .bar::before {
    bottom: 8px;
}

#header .hamburger.active .bar::before {
    bottom: 0;
}

#header .hamburger.active .bar::after {
    top: 0;
}
 
@media only screen and (min-width: 768px) {
    .cta {
        font-size: 2.5rem;
        padding: 20px 60px;
    }
    h1.section-title {
        font-size: 6rem;
    }

    #hero h1 {
        font-size: 7rem;
    }
    
    #services .service-bottom .service-item {
        flex-basis: 45%;
        margin: 2.5%;
    }
    
    #projects .project-item {
        flex-direction: row;
    }

    #projects .project-item:nth-child(even) {
        flex-direction: row-reverse;
    }

    #projects .project-item {
        height: 400px;
        margin: 0;
        width: 100%;
        border-radius: 0;
    }

    #projects .all-projects .project-info {
        height: 100%;
    }

    #projects .all-projects .project-img {
        height: 100%;
    }
     
    #about .about {
        flex-direction: row;
    }

    #about .col-left {
        width: 600px;
        height: 400px;
        padding-left: 60px;
    }

    #about .about .col-left .about-img::after {
        left: -45px;
        top: 34px;
        height: 98%;
        width: 98%;
        border: 10px solid crimson;
    }

    #about .col-right {
        text-align: left;
        padding: 30px;
    }

    #about .col-right h1 {
        text-align: left;
    }
  
    #contact .contact {
        flex-direction: column;
        padding: 100px 0;
        align-items: center;
        justify-content: center;
        min-width: 20vh;
    }

    #contact .contact-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 0;
    }

    #contact .contact-item {
        width: 30%;
        margin: 0;
        flex-direction: row;
    }

    #contact .contact-item .icon {
        height: 100px;
        width: 100px;
    }

    #contact .contact-item .icon img {
        object-fit: contain;
    }

    #contact .contact-item .contact-info {
        width: 100%;
        text-align: left;
        padding-left: 20px;
    }
}

@media only screen and (min-width: 899px) {
    #header .hamburger {
        display: none;
    }

    #header .nav-bar {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 50px;
      padding: 0 10px;
    }

    #header .nav-list ul {
        position: initial;
        display: block;
        height: auto;
        width: fit-content;
        background-color: transparent;
    }

    #header .nav-list ul li {
        display: inline-block;
    }

    #header .nav-list ul a:after {
        display: none;
    }
  
    #services .service-bottom .service-item {
        flex-basis: 22%;
        margin: 1.5%;
    }
      
    .header-menu {
        padding-top: 16px;
    }

    .header-menu .link-nav-bar {
        color: white;
        font-size: 16px;
        text-decoration: none;
    }

    .header-menu .header-menu-option {
        padding-top: 0px;
        padding-right: 32px;
    }

    .auth-nav-bar-button {
        color: #ffd274 !important;
        font-size: 16px !important;
        padding-bottom: 5px !important;
        text-transform: none !important;
        margin-bottom: 5px !important;
    }
}
  