.product-images-slider {
    .swiper-slide {
        padding-top: 70%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .swiper-button-prev:after {
        content: 'prev';
        font-size: 15px !important;
    }

    .swiper-button-prev {
        color: hsl(0, 0%, 100%);
        left: 20;
        border-radius: 20px;
        padding: 10px;
        border: 20px;
    }

    .swiper-button-next:after {
        content: 'next';
        font-size: 15px !important;
    }

    .swiper-button-next {
        color: hsl(0, 0%, 100%);
        left: 20;
        border-radius: 20px;
        font-size: 13px;
        padding: 10px;
        border: 20px;
    }
}

.rental-slider {
    .swiper-button-prev:after {
        content: 'prev';
        font-size: 15px !important;
    }

    .swiper-button-prev {
        color: hsl(185, 90%, 30%);
        left: 20;
        border-radius: 20px;
        font-size: 13px;
        padding: 10px;
        border: 20px;
        background-color: #adf2ed;
    }

    .swiper-button-next:after {
        content: 'next';
        font-size: 15px !important;
    }

    .swiper-button-next {
        color: hsl(185, 90%, 30%);
        left: 20;
        border-radius: 20px;
        font-size: 13px;
        padding: 10px;
        border: 20px;
        background-color: #adf2ed;
    }
}

.product-images-slider-thumbs {
    .swiper-slide {
        cursor: pointer;
        border: 1px solid #dddbdb;
        border-radius: 9px;

        &-thumb-active {
            border-color: #ff02f2;
        }
    }

    &-wrapper {
        width: 100%;
        padding-top: 65%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}