@media only screen and (min-width: 899px) {
    .card-booking {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffff;
        border-radius: 10px;
        padding: 10px 30px 40px 30px;
        width: 70%;
        text-align: left;
        font-family: "Playfair+Display", times, serif;
    }

    .slide-text-welcome{
        position: absolute;
        width: 100%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .slide-text-welcome h1{
        color: #ffff;
        font-size: 60px;
        font-family: "Playfair+Display", times, serif;
    }

    .slide-text-welcome p{
        color: #ffff;
        font-size: 20px;
    }

    .div-btn-booking{
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .btn-booking {
        border-radius: 35px !important;
        background-color: rgb(236, 164, 19) !important;
        padding: 2px 50px !important;
        font-size: 18px !important;
        text-align: center !important;
    }

    .btn-learn-more {
        border-radius: 35px !important;
        background-color: rgb(141, 201, 215) !important;
        padding: 2px 40px !important;
        font-size: 14px !important;
        text-align: center !important;
        text-transform: none !important;
    }

    .image-about-us {
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 899px) {
    .card-booking {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffff;
        border-radius: 10px;
        padding: 20px 40px 40px 40px;
        width: 70%;
    }
    
    .div-btn-booking{
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .btn-booking {
        width: 200px;
        border-radius: 35px !important;
        background-color: rgb(236, 164, 19) !important;
        padding: 6px 50px !important;
        font-size: 18px !important;
        text-align: center !important;
    }

    .btn-learn-more {
        border-radius: 35px !important;
        background-color: rgb(141, 201, 215) !important;
        padding: 2px 40px !important;
        font-size: 14px !important;
        text-align: center !important;
        text-transform: none !important;
    }

    .slide-text-welcome{
        position: absolute;
        width: 100%;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .slide-text-welcome h1{
        color: #ffff;
        font-size: 60px;
        font-family: "Playfair+Display", times, serif;
    }

    .slide-text-welcome p{
        color: #ffff;
        font-size: 20px;
    }

    .image-about-us {
        height: 80%;
        width: 80%;
        border-radius: 10px;
    }
}

.title{
    padding: 0 0 10px;
}

.title:after{
	content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    border-radius: 2px;
    background-color: #39c6ea;
}

#background1 {
    content: url("../../../../public/media/core/background1.jpeg");
}

#background2 {
    content: url("../../../../public/media/core/background2.jpeg");
}

#background3 {
    content: url("../../../../public/media/core/background3.jpeg");
}

@media screen and (max-width: 600px) {
    #background1 {
        content: url("../../../../public/media/core/background-mobile3.webp");
    }
    
    #background2 {
        content: url("../../../../public/media/core/background-mobile4.jpeg");
    }
    
    #background3 {
        content: url("../../../../public/media/core/background-mobile5.jpeg");
    }
}