.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root, 
.MuiAutocomplete-inputRoot.css-segi59 {
    height: 48px !important;
}

#txt-search-value {
    padding: 11px 0 5px;
}

input[type=file]::file-selector-button {
    border: 2px solid rgb(247, 215, 71);
    padding: .2em .4em;
    border-radius: .2em;
    background-color: rgb(247, 215, 71);
    transition: 1s;
}
  
input[type=file]::file-selector-button:hover {
background-color: rgb(247, 215, 71);
border: 2px solid rgb(247, 215, 71);
}
  